import { formatClinicListElementMenu } from '../Menu'
import type {
  ClinicListElementSource,
  ClinicListElement,
  ClinicSource,
  Clinic,
  ClinicCore,
} from './models'
import nonNullable from '~/utils/global/nonNullable'
import { formatArticleListElement } from '~/typeEntities/stores/Article'
import { formatDoctorMinData, formatDoctor } from '~/typeEntities/stores/Doctor'

export const formatClinicSnippet = (
  clinic: ClinicListElementSource
): ClinicListElement => {
  return {
    id: clinic.id,
    name: clinic.name,
    accessText: clinic.accessText,
    description: clinic.description,
    totalWeight: clinic.totalWeight,
    doctors: clinic.doctorAssignments.map((assignment) => {
      return formatDoctorMinData(assignment.doctor)
    }),
    menus: clinic.menus.map(formatClinicListElementMenu),
    reports: clinic.reports,
    articles: clinic.articles.map((article) => {
      return formatArticleListElement(article)
    }),
    section: clinic.section,
    photos: clinic.storePhotos.map((storePhoto) => {
      return {
        id: storePhoto.photo.id,
        imageUrl: storePhoto.photo.imageUrl,
        imageData: storePhoto.photo.imageData,
        alt: storePhoto.photo.alt,
      }
    }),
    reportsCount: clinic.reportsCount,
    totalReportRating: clinic.totalReportRating,
    menusCount: clinic.menusCount,
    doctorsCount: clinic.doctorsCount,
    medicalCasesCount: clinic.medicalCasesCount,
    articlesCount: clinic.articlesCount,
    address: clinic.address,
    latitude: clinic.latitude,
    longitude: clinic.longitude,
    officialUrl: clinic.officialUrl,
    businessDays: clinic.businessDays,
    facilityTypes: clinic.storeFacilityTypes.map((facilityType) => {
      return facilityType.facilityType
    }),
    clinicFeatures: clinic.clinicFeatures,
    stations: clinic.storeStations.map((storeStation) => {
      return {
        distance: storeStation.distance,
        id: storeStation.station.id,
        name: storeStation.station.name,
      }
    }),
  }
}
export const formatClinic = (clinic: ClinicSource): Clinic => {
  return {
    id: clinic.id,
    name: clinic.name,
    descriptionTitle: clinic.descriptionTitle,
    description: clinic.originalDescription || clinic.description,
    accessText: clinic.accessText,
    reportsCount: clinic.reportsCount,
    totalReportRating: clinic.totalReportRating,
    menusCount: clinic.menusCount,
    doctorsCount: clinic.doctorsCount,
    medicalCasesCount: clinic.medicalCasesCount,
    articlesCount: clinic.articlesCount,
    address: clinic.address,
    latitude: clinic.latitude,
    longitude: clinic.longitude,
    officialUrl: clinic.officialUrl,
    businessDays: clinic.businessDays,
    businessHoursNotes: clinic.businessHoursNotes,
    phoneNumber: clinic.phoneNumber,
    parkingText: clinic.parkingText,
    medicalLoanText: clinic.medicalLoanText,
    creditcardText: clinic.creditcardText,
    updatedAt: clinic.updatedAt,
    brand: clinic.brand,
    affiliationLink: clinic.affiliationLink,
    doFollowLink: clinic.doFollowLink,
    totalWeight: clinic.totalWeight,
    oneStarReportsCount: clinic.oneStarReportsCount,
    twoStarReportsCount: clinic.twoStarReportsCount,
    threeStarReportsCount: clinic.threeStarReportsCount,
    fourStarReportsCount: clinic.fourStarReportsCount,
    fiveStarReportsCount: clinic.fiveStarReportsCount,
    stations: clinic.storeStations.map((storeStation) => {
      return {
        distance: storeStation.distance,
        id: storeStation.station.id,
        name: storeStation.station.name,
      }
    }),
    facilityTypes: clinic.storeFacilityTypes.map((facilityType) => {
      return facilityType.facilityType
    }),
    doctors: clinic.doctorAssignments.map((assignment) => {
      return {
        ...formatDoctor(assignment.doctor),
        jobPosition: assignment.jobPosition,
      }
    }),
    menus: clinic.menus,
    section: clinic.section,
    reports: clinic.reports,
    articles: clinic.articles.map((article) => {
      return formatArticleListElement(article)
    }),
    photos: clinic.storePhotos.map((storePhoto) => {
      return storePhoto.photo
    }),
    treatmentIds: clinic.clinicSearchSummaries
      .map((summary) => {
        return summary.treatmentId
      })
      .filter(nonNullable),
    clinicSearchSummaries: clinic.clinicSearchSummaries,
    listSubjects: clinic.storeListSubjects.map((sls) => sls.listSubject),
    medicalCases: clinic.medicalCases,
    clinicFeatures: clinic.clinicFeatures,
    youtubeVideos: clinic.storeYoutubes.map((sv) => sv.youtubeVideo),
  }
}
export const formatToSavedClinic = (clinic: {
  id: number
  name: string | null
  reportsCount: number
  totalWeight: number
  totalReportRating: number
  accessText: string | null
  photos?: {
    id: number
    alt: string | null
    imageUrl: string | null
  }[]
  photo?: {
    id: number
    alt: string | null
    imageUrl: string | null
  } | null
}) => {
  const photosProp = (() => {
    if (clinic.photos) {
      return clinic.photos
    } else if (clinic.photo) {
      return [clinic.photo]
    } else {
      return []
    }
  })()
  return {
    id: clinic.id,
    name: clinic.name,
    reportsCount: clinic.reportsCount,
    totalWeight: clinic.totalWeight,
    totalReportRating: clinic.totalReportRating,
    accessText: clinic.accessText,
    photos: photosProp,
    memo: '',
    savedAt: new Date(),
    editedAt: new Date(),
  }
}

export const formatToClinicCore = (clinic: Clinic): ClinicCore => {
  return {
    id: clinic.id,
    name: clinic.name,
    accessText: clinic.accessText,
    address: clinic.address,
    phoneNumber: clinic.phoneNumber,
    reportsCount: clinic.reportsCount,
    totalReportRating: clinic.totalReportRating,
    totalWeight: clinic.totalWeight,
    storePhotos: clinic.photos.map((photo) => {
      return {
        photo,
      }
    }),
    businessDays: clinic.businessDays,
    businessHoursNotes: clinic.businessHoursNotes,
  }
}

export const getNearestStation = (clinic: Clinic) => {
  return clinic.stations.reduce((min, current) => {
    return Number(current.distance) < Number(min.distance) ? current : min
  }, clinic.stations[0])
}
